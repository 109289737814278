import React from 'react';
import Title from '../Components/Title';

function NoMatch() {
    return (
        <div>
            <div className="title">
                <Title title={'404 Page Not Found'} span={'404 Page Not Found'} />
            </div>
                <p>
                    The page you are looking for could not be found.
                </p>
        </div>
    )
}

export default NoMatch;