import React from 'react'
import ContactItem from '../Components/ContactItem';

import email from '../img/emailme.svg';
import Title from '../Components/Title';

import ContactEmail from '../Components/ContactEmail';

function ContactPage() {
    const [showingEmail, setShowingEmail] = React.useState(false);

    let myEmail = showingEmail ? (
        <ContactEmail />
    ) : (
        <button onClick={() => setShowingEmail(true)}>
            Click for contact email
        </button>
    )

    return (
        <div>
            <div className="title">
                <Title title={'Contact Me'} span={'Contact Me'} />
            </div>
            <div className="ContactPage">
                <div className="contact-sect">
                    <ContactItem icon={email} text1={<React.Suspense>
                        <div>
                            {myEmail}
                        </div>
                    </React.Suspense>
                } text2={''} title={'Email'}/>
                </div>
            </div>
        </div>
    )
}

export default ContactPage;
