import './App.scss';
import NavBar from './Components/NavBar';
import HomePage from './Pages/HomePage';
import {Switch, Route} from 'react-router-dom';
import AboutPage from './Pages/AboutPage';
import ProjectsPage from './Pages/ProjectsPage';
import ContactPage from './Pages/ContactPage';
import NoMatch from './Pages/NoMatch';
import { useState } from 'react';
import Konami from 'react-konami-code';

import EasterEgg from './img/easteregg.mp3';

function easterEgg() {
    const audio = document.querySelector("audio");
    audio.volume = 0.1;
    audio.play();
}

function App() {
  const [navToggle, setNavToggle] = useState(false);

  const navClick = () =>{
    setNavToggle(!navToggle)
  }

  return (
    <div className="App">
      <div className={`sidebar ${navToggle ? 'nav-toggle': ''}`}>
        <NavBar />
      </div>
      <div className="nav-btn" onClick={navClick}>
        <div className="lines-1"></div>
        <div className="lines-2"></div>
        <div className="lines-3"></div>
      </div>
      <div className="main-content">
        
        <Konami action={easterEgg}>
          <audio src={EasterEgg}></audio>
        </Konami>

          <div className="content">
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/about" exact component={AboutPage} />
              <Route path="/projects" exact component={ProjectsPage} />
              <Route path="/contact" exact component={ContactPage} />
              <Route path="*" component={NoMatch} />
            </Switch>
          </div>
      </div>
    </div>
  );
}

export default App;
