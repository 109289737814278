import React from 'react';
import about from '../img/placeholder.png';

function AboutSection() {
    return (
        <div className="AboutSection">
            <div className="img">
                <img src={about} alt="" width="266" height="400"/>
            </div>
            <div className="about-info">
                <h4>Hi, I'm <span> Connor</span></h4>
                <p className="about-text">
                    I am a heavily motivated developer with a passion for programming and
                    learning the latest and greatest technologies and someone who can 
                    comfortably work within a team environment, communicate effectively 
                    and convey ideas concisely.
                </p>
                <div className="about-details">
                    <div className="left-section">
                        <p>Full Name</p>
                        <p>Occupation</p>
                        <p>Nationality</p>
                        <p>Languages</p>
                        <p>Country</p>
                    </div>
                    <div className="right-section">
                        <p>: Connor Wright</p>
                        <p>: Software Developer</p>
                        <p>: British</p>
                        <p>: English &amp; German</p>
                        <p>: United Kingdom</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSection;
