import React from 'react'
import Title from '../Components/Title'
import AboutSection from '../Components/AboutSection';
import SkillsSection from '../Components/SkillsSection';
import ExperienceSection from '../Components/ExperienceSection';

import ulht from '../img/ulht.png';
import symphonyretailai from '../img/symphonyretailai.jpg';
import ecdl from '../img/ecdl.jpg';
import remarkable from '../img/remarkable.png';
import madgex from '../img/madgex.png';

import Certification from '../Components/Certification';

const ecdlDescriptions = [
    'I achieved a Distinction* in my ECDL course, equivalent to an A*.',
    'This is a computer literacy certificate that shows you are able to use',
    'common word processing applications such as Word, PowerPoint, Excel and more' 
];

function AboutPage() {
    return (
        <div className="AboutPage">
            <Title title={'About'} span={'About'} />
            <AboutSection />
            <Title title={'My Skills'} span={'My Skills'} />
            <div className="skillsContainer">
                <SkillsSection skill={'C#'} />
                <SkillsSection skill={'SQL Server'} />
                <SkillsSection skill={'JavaScript'} />
                <SkillsSection skill={'Python'} />
                <SkillsSection skill={'Microsoft Azure'} />
                <SkillsSection skill={'MySQL'} />
            </div>

            <Title title={'Experience'} span={'Experience'} />
            <div className="experiences-container">
                <ExperienceSection 
                    image={ulht}
                    title={'ULH NHS Trust'}
                    jobTitle="ICT Applications Developer"
                    text={'Created a stock control system for internal use in .NET utilising SQL Server, DevExpress and jQuery'}
                    startDate="2019-07-01"
                    endDate="2019-10-01"
                />

                <ExperienceSection 
                    image={symphonyretailai}
                    title={'Symphony RetailAI'} 
                    jobTitle="Software Developer"
                    text={'Worked on a retailer CRM used by retailers internationally, written in .NET and utilising jQuery, Knockout.js, SQL Server, Azure DevOps and Microsoft Azure'}
                    startDate="2019-10-01"
                    endDate="2021-09-01"
                />

                <ExperienceSection
                    image={remarkable}
                    title={'Remarkable Commerce'}
                    jobTitle="Software Developer"
                    text={'Working on the Yours Clothing team (incl. Bad Rhino & Long Tall Sally) doing full stack development using SQL Server, MVC, C# and using Entity Framework'}
                    startDate="2021-09-06"
                    endDate="2022-04-11"
                />

                 <ExperienceSection
                    image={madgex}
                    title={'Madgex'}
                    jobTitle="Software Developer"
                    text={'TBA'}
                    startDate="2022-04-11"
                    endDate="Present"
                />

            

            </div>

            <Title title={'Certifications'} span={'Certifications'} />
            <div className="experiences-container">
                <Certification image={ecdl} certification={'European Computer Driving License'} description={ecdlDescriptions} />
            </div>

        </div>
    )
}

export default AboutPage;
