import React from 'react'
import avatar from '../img/profilepicture.jpg';
import {NavLink} from 'react-router-dom';

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Navbar() {
    return (
        <div className="NavBar">
            <nav className="nav">
                <div className="profile">
                    <img src={avatar} alt=""/>
                 </div>

                <ul className="nav-items">
                    <li className="nav-item">
                        <NavLink to="/" exact activeClassName="active">
                            Home
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/about" exact activeClassName="active">
                            About
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/projects" exact activeClassName="active">
                            Projects
                        </NavLink>
                    </li>
                    <li className="nav-item" title="Opens in a new tab">
                        <a href="https://blog.connorwright.uk" rel="noreferrer" target="_blank">
                            Blog <FontAwesomeIcon icon={faExternalLinkAlt}></FontAwesomeIcon>
                        </a>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/contact" exact activeClassName="active">
                            Contact
                        </NavLink>
                    </li>
                </ul>

                <footer className="footer">
                    <p>
                        &copy; Connor Wright
                    </p>
            </footer>
            </nav>
            
        </div>
    )
}

export default Navbar;
