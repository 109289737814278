import React from 'react';

function ContactEmail() {
    return (
        <div>
            <a href="mailto:connor@connorwright.uk">
                connor@connorwright.uk
            </a>
        </div>
    )
}

export default ContactEmail;