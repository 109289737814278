import moment from 'moment';
import React from 'react';

function ExperienceSection({image, title, text, startDate, endDate, jobTitle}) {
    return (
        <div className="ExperienceSection">
            <div className="experience">
                <div className="experience-content">
                    <img src={image} alt={title} class="centre"/>
                    <h5 className="e-title">
                        {title}
                    </h5>

                    <small>{jobTitle}</small>
                    <br />
                    <small>{getHumanReadableDate(startDate)} - {getHumanReadableDate(endDate)} ({calculateEmploymentTime(startDate, endDate)})</small>
                    
                    <p className="e-text">
                        {text}
                    </p>
                </div>
            </div>
        </div>
    )
}

const MONTHS = [
    { num: 1, short: "Jan", long: "January" },
    { num: 2, short: "Feb", long: "February" }, 
    { num: 3, short: "Mar", long: "March" },          
    { num: 4, short: "Apr", long: "April" },
    { num: 5, short: "May", long: "May" },
    { num: 6, short: "Jun", long: "Juni" },
    { num: 7, short: "Jul", long: "July" },
    { num: 8, short: "Aug", long: "August" },
    { num: 9, short: "Sep", long: "September" },
    { num: 10, short: "Oct", long: "October" },
    { num: 11, short: "Nov", long: "November" },
    { num: 12, short: "Dec", long: "December" }
  ];
        

function convertMonth(i = 0, option = "num") {
    return MONTHS[i][option];
}

function getHumanReadableDate(date) {
    if (date === "Present")
        return date;

    var parsedDate = new Date(date);
    var month = convertMonth(parsedDate.getMonth(), "short");
    var year = parsedDate.getFullYear();

    return `${month} ${year}`;
}

function getDateParts(date) {
    var parts = date.split("-");
    var year = parseInt(parts[0]);
    var month = parseInt(parts[1]);
    var day = parseInt(parts[2]);

    return [year, month, day];
}

function momentHumanize(eventDuration, unit) {
    var eventMDuration = moment.duration(eventDuration, unit);
    var eventDurationArray = [];

    if (eventMDuration.years() > 0) {
        if (eventMDuration.years() === 1) {
            eventDurationArray.push(eventMDuration.years() + ' year');
            eventMDuration.subtract(eventMDuration.years(), 'year')
        } else {
            eventDurationArray.push(eventMDuration.years() + ' years');
            eventMDuration.subtract(eventMDuration.years(), 'years');
        }
    }

    if (eventMDuration.months() > 0) {
        eventDurationArray.push(eventMDuration.months() + ' months');
        eventMDuration.subtract(eventMDuration.months(), 'months')
    }
   
    return eventDurationArray.length === 1 ? eventDurationArray[0] : eventDurationArray.join(' ');
}

function calculateEmploymentTime(start, end) {
    var startParts = getDateParts(start);
    var endParts;

    if (end === "Present") {
        var currentDate = new Date();
        var constructedDate = `${currentDate.getFullYear()}-${currentDate.getMonth() === 11 ? 11 : currentDate.getMonth() + 1}-${currentDate.getDate()}`;
        endParts = getDateParts(constructedDate);
    } else {
        endParts = getDateParts(end);
    }

    var a = moment(startParts);
    var b = moment(endParts);

    var momentDifference = b.diff(a);

    var difference = momentHumanize(momentDifference, 'milliseconds');
    return difference;
}

export default ExperienceSection;
