import React from 'react';

function Certification({image, certification, description}) {
    return (
        <div className="ExperienceSection">
        <div className="experience">
            <div className="experience-content">
                <img src={image} alt="certification" className="centre"/>
                <h5 className="e-title">
                    {certification}
                </h5>

                <p className="e-text">
                    {description.map(x => (<p>{x}</p>))}
                </p>
            </div>
        </div>
    </div>
    )
}

export default Certification;