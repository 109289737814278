import asphalt from '../img/portImages/asphalt.png';
import wip from '../img/portImages/wip.png';
import watchtower from '../img/portImages/watchtower.png';
import lockza from '../img/portImages/lockza.png';

const portfolios = [
    {
        id: 1,
        category: 'C#',
        link1: 'https://asphaltbot.com',
        link2: 'https://blog.connorwright.uk/2021/05/03/about-asphalt/',
        icon1: 'Website',
        icon2: 'Info',
        image: asphalt,
        title: 'Asphalt',
        paragraph: "Asphalt is a bot that interacts with the Discord platform. It currently has 76,000,000+ users"
    },
    {
        id: 2,
        category: 'C#',
        link1: 'https://censorme.tech',
        link2: 'https://blog.connorwright.uk/2021/05/03/about-censorme/',
        icon1: 'Website',
        icon2: 'Info',
        image: wip,
        title: 'CensorMe',
        paragraph: "CensorMe is an early work in progress, which allows people to upload audio clips and specify a list of words that they wish to automatically censor"
    },
    {
        id: 3,
        category: 'C#',
        link1: '',
        link2: 'https://blog.connorwright.uk/2021/05/03/about-twitchglobalbans/',
        icon1: 'Website',
        icon2: 'Info',
        image: wip,
        title: 'TwitchGlobalBans',
        paragraph: "TwitchGlobalBans is a Twitch bot that creates a global ban list which allows users to report malicious accounts and spam bots"
    },
    {
        id: 4,
        category: 'C#',
        link1: 'https://watchtower.software',
        link2: 'https://blog.connorwright.uk/2021/05/03/about-watchtower/',
        icon1: 'Website',
        icon2: 'Info',
        image: watchtower,
        title: 'Watchtower',
        paragraph: "Watchtower is a work in progress which will allow users to set up monitors for their websites, services (mysql, ssh etc) and set up alerts to notify them when they go offline"
    },
    {
        id: 5,
        category: 'C#',
        link1: '#',
        link2: '#',
        icon1: 'N/A',
        icon2: 'N/A',
        image: lockza,
        title: 'Lockza',
        paragraph: "Lockza is a desktop application which allows for file encryption and decryption using your USB as a key (when the USB is unplugged, the selected files will be re-encrypted)"
    }
]

export default portfolios;